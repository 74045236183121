import { SharedModelState } from '../shared.model.state';

export interface CorrectionState extends SharedModelState<CorrectionModel> {}

export interface CorrectionModel {
  id?: string;
  user_id: string;
  time_off_balance_id?: string;
  date: string;
  hours?: string;
  days?: string;
  note: string;
  type: string;
  pay: boolean;
  payout_date?: string;
  action: CorrectionActionType;
  loading?: boolean;
  deleted?: boolean;
  created_by: string;
  createdByName?: string;
}

export enum CorrectionType {
  OVERTIME = 'Overtime',
  TIME_OFF_BALANCE = 'Time off balance',
}

export enum CorrectionActionType {
  MUTATE = 'mutate',
  BALANCE_MOVE = 'balance move',
  OVERTIME_MOVE = 'overtime move',
  PAY = 'pay',
}

export enum BalanceCorrectionType {
  UPDATE_TO = 'update_to',
  ADD_TO = 'correct_to',
  CORRECT_NEGATIVE_TO = 'correct_negative_to',
  CORRECT_POSITIVE_TO = 'correct_positive_to',
}
