import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { forkJoin as observableForkJoin, forkJoin, Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { CorrectionSchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { objectToSearchParams } from '../../../api/api.helper';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import chunk from 'lodash-es/chunk';
import { FeatureService } from '../../../startup/feature.service';
import { CorrectionActionType, CorrectionType } from './correction.model';

export interface LoadCorrectionRequest {
  min_date: string;
  max_date: string;
  user_id?: string;
  type?: 'Overtime' | 'Vacation hours' | 'Time off balance';
}

export interface SaveCorrectionRequest {
  action: CorrectionActionType;
  type: CorrectionType;
  date: string;
  note: string;
  user_id: string;
  hours?: number;
  days?: number;
  time_off_balance_id?: string;
  time_off_balance_name?: string;
  to_time_off_balance_id?: string;
  to_time_off_balance_name?: string;
  payout_date?: string;
}

@Injectable()
export class CorrectionApi {
  private endpoint = 'corrections/';
  private statsEndpoint = 'stats/';

  private maxChunkSize = 250;

  public constructor(private gateway: ApiGateway, private features: FeatureService) {}

  public load(requestData: LoadCorrectionRequest, dispatchStart?: Action): Observable<any> {
    const search = objectToSearchParams(requestData);

    return this.gateway
      .get(
        this.endpoint,
        {
          params: search,
        },
        dispatchStart
      )
      .pipe(
        map((res) => reformatListResponse('Correction', res)),
        map((data) => normalize(data, arrayOf(CorrectionSchema), { assignEntity: assignSchemaEntity }))
      );
  }

  public add(correctionData, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, correctionData, undefined, dispatchStart).pipe(
      map((res) => reformatListResponse('Correction', res)),
      map((data) => normalize(data, arrayOf(CorrectionSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  public update(id, data, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + id, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Correction', res)),
      map((res) => normalize(res, CorrectionSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  public fetch(correctionId, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(this.endpoint + correctionId, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Correction', res)),
      map((res) => normalize(res, CorrectionSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  public remove(correctionId, dispatchStart?: Action): Observable<any> {
    return this.gateway.delete(this.endpoint + correctionId, undefined, dispatchStart);
  }

  public getTimeOffBalances(date: string, userId: string) {
    return this.gateway.get('users/' + userId + '/time_off/balances', { params: { date: date } });
  }

  public getBalance(type: string, date: string, ...users: string[]) {
    return this.gateway.post('balance/' + type + '/' + date, { user_ids: users }).pipe(map((res) => res.balance));
  }

  public loadPlusMinStats(statsData): Observable<any> {
    return this.gateway.post(this.statsEndpoint + 'plus_min', statsData);
  }

  public loadTimeOffBalanceStats(statsData): Observable<any> {
    const userChunks = chunk(statsData.ids, this.maxChunkSize);

    const requests = userChunks.map((userChunk) => {
      const userIds = userChunk.join(',');
      const search = objectToSearchParams({ date: statsData.endDate, userIds });
      return this.gateway.get('users/time_off/balances/' + statsData.timeOffBalancesId, {
        params: search,
      });
    });

    return observableForkJoin(requests).pipe(
      map((responses) => responses.reduce((acc, response) => Object.assign(acc, response), {}))
    );
  }

  public saveCorrection(correctionData): Observable<any> {
    return this.gateway.post(this.endpoint + 'batch/', correctionData);
  }
}
