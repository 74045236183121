import { Injectable } from '@angular/core';
import { AbsenceOptionUnit } from '@app/enums';
import { Dictionary } from '@ngrx/entity';
import { compose, Store } from '@ngrx/store';
import filter from 'lodash-es/filter';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { getEmployeeEntities } from '../employee/employee.service';
import { TimeOffBalanceModel } from '../time-off-balance/time-off-balance.model';
import { CorrectionAction } from './correction.action';
import { CorrectionApi, LoadCorrectionRequest } from './correction.api';
import { CorrectionActionType, CorrectionModel, CorrectionState, CorrectionType } from './correction.model';

@Injectable()
export class CorrectionService {
  private _changed$ = new Subject();

  public constructor(
    private store: Store<AppState>,
    private api: CorrectionApi,
  ) {}

  public load(requestData: LoadCorrectionRequest) {
    return this.api.load(requestData, CorrectionAction.load(requestData)).pipe(
      map((response) => {
        this.store.dispatch(CorrectionAction.loadSuccess(requestData, response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(CorrectionAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(correctionData): Observable<any> {
    return this.api.add(correctionData, CorrectionAction.add(correctionData)).pipe(
      map((response) => {
        this.store.dispatch(CorrectionAction.addSuccess(response));
        this._changed$.next(1);

        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(CorrectionAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, correctionData) {
    return this.api.update(id, correctionData, CorrectionAction.update(correctionData)).pipe(
      map((response) => {
        this.store.dispatch(CorrectionAction.updateSuccess(response));
        this._changed$.next(1);

        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(CorrectionAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    return this.api.fetch(id, CorrectionAction.fetch(id)).pipe(
      map((response) => {
        this.store.dispatch(CorrectionAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(CorrectionAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(id) {
    return this.api.remove(id, CorrectionAction.remove(id)).pipe(
      map((response) => {
        this.store.dispatch(CorrectionAction.removeSuccess(id));
        this._changed$.next(1);

        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(CorrectionAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(correctionData) {
    if (correctionData.id) {
      return this.update(correctionData.id, correctionData);
    }

    correctionData = u.omit('id', correctionData);

    return this.add(correctionData);
  }

  public getTimeOffBalances(date: string, userId) {
    return this.api.getTimeOffBalances(date, userId);
  }

  public getBalance(type: string, date: string, ...users: string[]) {
    return this.api.getBalance(type, date, ...users);
  }

  public listenToChanges() {
    return this._changed$;
  }

  /**
   * Method to load plus min stats
   * @param statsData
   * @returns {Observable<any>}
   */
  public loadPlusMinStats(statsData): Observable<any> {
    return this.api.loadPlusMinStats(statsData);
  }

  public loadEmployeePlusMinStats(userId, startDate, endDate, groupBy?): Observable<any> {
    return this.api
      .loadPlusMinStats({
        startDate,
        endDate,
        ids: [userId],
        groupBy,
      })
      .pipe(map((response) => response[userId]));
  }

  public loadTimeOffBalanceStats(statsData): Observable<any> {
    return this.api.loadTimeOffBalanceStats(statsData);
  }

  /**
   * Method to save correction data for both vacation and plus-min
   * @param correctionData
   * @returns {Observable<any>}
   */
  public saveCorrection(correctionData): Observable<any> {
    return this.api.saveCorrection(correctionData);
  }

  // time off -> plus min OR plus min -> time off move
  public isOvertimeMoveAction(type, action) {
    if (action === CorrectionActionType.OVERTIME_MOVE) return true;

    return type === CorrectionType.OVERTIME && !Object.values(CorrectionActionType).includes(action);
  }

  // time off -> time off move
  public isBalanceMoveAction(type, action) {
    if (action === CorrectionActionType.OVERTIME_MOVE) return false;
    if (type === CorrectionType.OVERTIME) return false;

    return !Object.values(CorrectionActionType).includes(action);
  }

  public getCorrectionRequestPayload(
    formData,
    correctionType: CorrectionType,
    convertedCorrection: number | null = null,
    balancesById: Dictionary<TimeOffBalanceModel>,
    fromBalanceUnit: AbsenceOptionUnit,
    toBalanceUnit: AbsenceOptionUnit,
  ): CorrectionModel {
    // Set correct hours and/or days
    const correction = formData.correctionAmount;
    if (convertedCorrection) {
      if (fromBalanceUnit === AbsenceOptionUnit.DAYS) {
        formData.days = correction;
        formData.hours = convertedCorrection;
      } else {
        formData.hours = correction;
        formData.days = convertedCorrection;
      }
    } else if (toBalanceUnit === AbsenceOptionUnit.DAYS) {
      formData.days = correction;
    } else {
      formData.hours = correction;
    }

    delete formData.correctionAmount;

    if (formData.hoursInDay) {
      formData['hours_in_day'] = formData.hoursInDay;
      delete formData.hoursInDay;
    }

    const toBalanceId = formData.action;
    const fromBalanceId = formData['time_off_balance_id'];

    if (formData.action === CorrectionActionType.MUTATE) {
      delete formData.action;
    } else if (this.isBalanceMoveAction(correctionType, formData.action)) {
      formData = {
        ...formData,
        action: CorrectionActionType.BALANCE_MOVE,
        time_off_balance_name: balancesById[fromBalanceId]['name'],
        to_time_off_balance_id: toBalanceId,
        to_time_off_balance_name: balancesById[toBalanceId]['name'],
      };
    } else if (this.isOvertimeMoveAction(correctionType, formData.action)) {
      formData.action = CorrectionActionType.OVERTIME_MOVE;

      if (correctionType === CorrectionType.OVERTIME) {
        formData = {
          ...formData,
          to_time_off_balance_id: toBalanceId,
          to_time_off_balance_name: balancesById[toBalanceId] ? balancesById[toBalanceId]['name'] : '',
        };
      } else {
        formData = {
          ...formData,
          time_off_balance_name: balancesById[fromBalanceId] ? balancesById[fromBalanceId]['name'] : '',
        };
      }
    }

    return formData;
  }
}

export const sortCorrections = (corrections: CorrectionModel[]): CorrectionModel[] =>
  sortBy(corrections, ['date', 'created']);
export const mapAndSortCorrections = mapAndSortEntities(sortCorrections);

export const getCorrectionState = (appState: AppState): CorrectionState => appState.orm.corrections;

export const getCorrectionIds = compose((state) => state.items, getCorrectionState);
export const getCorrectionEntities = createSelector(getCorrectionState, (state) => state.itemsById);
export const getCorrections = createSelector(getCorrectionIds, getCorrectionEntities, mapAndSortCorrections);

export const getCorrection = (id: string) =>
  createSelector(getCorrectionEntities, (entities) => mapEntity(id, entities));

export const getUserCorrections = (userId: string, minDate: string, maxDate: string, type: string) =>
  createSelector(getCorrections, getEmployeeEntities, (corrections: CorrectionModel[], employeeEntities) =>
    filter(corrections, (correction) => {
      if (correction.deleted) {
        return false;
      }

      if (correction.user_id !== userId) {
        return false;
      }

      if (correction.date < minDate || correction.date > maxDate) {
        return false;
      }

      if (!!type && type !== correction.type) {
        return false;
      }

      return true;
    })
      //add Created By name
      .map((correction: CorrectionModel) => {
        if (!correction.created_by || !employeeEntities || !employeeEntities[correction.created_by]) {
          return correction;
        }

        const createdBy = employeeEntities[correction.created_by];

        return {
          ...correction,
          createdByName: createdBy.name,
        };
      }),
  );
